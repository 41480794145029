.logo, .logo-link {
    max-height: 45px;
    line-height: 0;
    width: 170px;
}
.MuiToolbar-root a.active, 
.MuiToolbar-root a:hover {
    color: #ffed00;
}

.MuiListItemButton-root.active {
    background-color: #42aee8;
}
.MuiListItemButton-root.active span,
.MuiListItemButton-root.active svg {
    color: #ffed00;
}