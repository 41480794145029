.seo-section h1 {
    margin: 0;
    margin-bottom: 8px;
}
.seo-section h2 {
    margin: 0;
    margin-bottom: 6px;
    margin-top: 8px;
}
.seo-section p {
    margin: 0;
}